import React from "react";
import { Modal } from "@mui/material";
import { motion } from "framer-motion";
const EnterModal = ({
  open,
  selectItem,
  enterIntoCoin,
  handelEnterModalClose,
}) => {
  const handleYesClick = (e) => {
    e.preventDefault();
    enterIntoCoin(selectItem);
  };
  return (
    <Modal
      open={open}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "white",
          height: 170,
          width: 400,
          padding: 20,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: 8 }}>
          <p
            style={{
              color: "blue",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Do You Want To Enter Into{" "}
            <span style={{ color: "red", textAlign: "center" }}>
              {" "}
              "{selectItem.name}"
            </span>
          </p>
        </div>
        <div style={{ flex: 2, display: "flex", gap: 10 }}>
          <motion.button
            style={{
              color: "white",
              background: "#227B94",
              border: "none",
              height: 30,
              width: 60,
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={handleYesClick}
            whileHover={{ background: "black", color: "white", scale: 1.05 }}
          >
            YES
          </motion.button>
          <motion.button
            style={{
              color: "white",
              background: "#227B94",
              border: "none",
              height: 30,
              width: 60,
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={handelEnterModalClose}
            whileHover={{ background: "black", color: "white", scale: 1.05 }}
          >
            NO
          </motion.button>
        </div>
      </div>
    </Modal>
  );
};
export default EnterModal;
